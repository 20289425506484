import { colors, typography } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  height: 500px;
`

export const PanelTitle = styled.p`
  ${typography.h300}
  margin-top: 8px;
`

export const Panel = styled.div`
  flex: 1 0 48%;
  padding-right: 24px;
  padding-bottom: 16px;

  & + & {
    border-left: 1px solid ${colors.backgroundHover()};
    padding-right: 0;
    padding-left: 24px;
  }
`

export const PanelInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
