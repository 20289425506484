import { useCallback, useMemo, ComponentProps } from 'react'
import { useHistory } from 'react-router-dom'

import { CreateTeamInput, useCreateTeamMutation } from '../../../graphql'
import useValues from '../../../lib/useValues'
import Modal from '../../Modal'
import { LoadingSpinner } from '../../Spinner'
import { Field, TextField } from '../../form'
import UserSelect from '../../users/Select'

import { Outer, Panel, PanelInner, PanelTitle } from './styled'

const CreateTeamModal = ({
  onClose,
  ...modalProps
}: ComponentProps<typeof Modal>) => {
  const history = useHistory()
  // Team values
  const [values, { setters, reset }] = useValues<Partial<CreateTeamInput>>({}, [
    'principalUserId',
    'name',
    'maxioSubscriptionId',
  ])

  const isValidForm = useMemo(() => {
    return (
      !!values.principalUserId && !!values.name && !!values.maxioSubscriptionId
    )
  }, [values])

  const onCloseComplete = useCallback(() => {
    reset()
    onClose && onClose()
  }, [onClose, reset])

  const [createTeam, { loading }] = useCreateTeamMutation({
    onCompleted: (data) => {
      history.push(`/teams/${data.createTeam.id}`)
      onCloseComplete()
      window.location.reload()
    },
  })

  const onSubmit = useCallback(() => {
    if (!values.principalUserId) {
      return
    }
    createTeam({
      variables: {
        input: {
          principalUserId: values.principalUserId,
          name: values.name,
          maxioSubscriptionId: values.maxioSubscriptionId,
        },
      },
    })
  }, [
    createTeam,
    values.maxioSubscriptionId,
    values.name,
    values.principalUserId,
  ])

  return (
    <Modal
      onCloseComplete={onCloseComplete}
      width={'large'}
      heading={'Create new team'}
      autoFocus
      actions={[
        {
          text: 'Create',
          onClick: onSubmit,
          isDisabled: !isValidForm,
          isLoading: loading,
        },
        { text: 'Cancel', onClick: onClose },
      ]}
      {...modalProps}
    >
      <Outer>
        <Panel>
          <PanelTitle>{'Team Configuration'}</PanelTitle>
          <PanelInner>
            <Field
              label={'Team Principal'}
              helperMessage={
                'This user will be created and set as the team principal'
              }
              isRequired
            >
              <UserSelect
                value={
                  values.principalUserId
                    ? { id: values.principalUserId }
                    : undefined
                }
                onChange={(user) => {
                  user && setters.principalUserId?.(user.id)
                }}
              />
            </Field>

            <Field label={'Team Name'} isRequired>
              <TextField
                value={values.name || ''}
                onChangeValue={setters.name}
              />
            </Field>
            <Field label={'Maxio Subscription Id'} isRequired>
              <TextField
                value={values.maxioSubscriptionId || ''}
                onChangeValue={setters.maxioSubscriptionId}
              />
            </Field>
          </PanelInner>
        </Panel>

        <LoadingSpinner show={loading} />
      </Outer>
    </Modal>
  )
}

export default CreateTeamModal
