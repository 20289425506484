import { PropsWithChildren, useMemo } from 'react'

import {
  onlyIfAdminable,
  useGetAdminableConfigQuery,
} from '../../../../graphql'

const OnlyIfAdminRoles = ({
  userId,
  roles,
  requiresAllRoles = true,
  children,
}: PropsWithChildren<{
  userId: string
  roles: string[]
  requiresAllRoles?: boolean
}>) => {
  const { data } = useGetAdminableConfigQuery({
    variables: {
      userId,
    },
  })

  const adminable = onlyIfAdminable(data?.user)

  const matchesRoles = useMemo(() => {
    return requiresAllRoles
      ? roles.every((role) =>
          adminable?.adminRoles?.map((ar) => ar.id).includes(role),
        )
      : roles.some((role) =>
          adminable?.adminRoles?.map((ar) => ar.id).includes(role),
        )
  }, [adminable, requiresAllRoles, roles])

  if (!matchesRoles) {
    return null
  }

  return <>{children}</>
}

export default OnlyIfAdminRoles
